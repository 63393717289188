@charset "utf-8";



// Our variables
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #fee9f8;
$background-color: #000000; //#26062a;
$outer-background-color: #26062a; //#000000; //#462b49;
//#5c3f5e;
$header-color:	   #000000; //#26062a;
// Outline colors
$flare-color:      #915da6; //#e704f8; //#ee96d5; // light pink

// link color
$brand-color:      darken(#ff6ec7, 15%); //#ca63b2; // decafe ; //#e704f8; //8dd33ff;


$grey-color:       lighten(#e704f8, 40%);
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

$code-background: #26062a; //darken($grey-color, 70%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;

.site-header {
	background-color: $header-color;
	
	.site-title {
		color: $brand-color;
	}

	.site-nav {
		.page-link {
			color: $brand-color;

			&:hover {
				text-decoration: none;
				color: $text-color;
			}
		}
		
		@include media-query($on-palm) {
			.page-link {
				color: $brand-color;
			}
		}
	}
}

.site-footer {
	background-color: $header-color;
	
	color: $grey-color;

	a { 
		color: $brand-color;
	}
}

.divider {
	width: 100%;
	border-top: 1px solid $flare-color;
	margin-bottom: 20px;
}

.page-content {
	background-color: $outer-background-color;
        //background-image: linear-gradient(to right, $outer-background-color, $background-color, $outer-background-color);
        //background-image: linear-gradient(to right bottom, darken(red, 30%), purple, darken(pink, 15%), purple, darken(blue, 30%));
	
	// cyberpunk neons blue and pink
	//background-image: linear-gradient(to right bottom, deeppink, cyan, deeppink, cyan, deeppink);	

	// Dark red to purple to pink to purle to dark blue
        background-image: linear-gradient(to right bottom, darken(red, 40%),  darken(purple, 15%), darken($brand-color, 30%),  darken(purple, 10%), darken(blue, 40%));
}

.bg-overlay {
  // duplicated in head.htlm as preload to speed loading by cutting out a request
  background: url(/resources/bg/astro_black.png);
  z-index: -1;
}

.table-active td {
	background-color: #104010;
}

.table-retired td {
	background-color: #401010;
}

.post-content table {
	margin-bottom: 25px;
}
